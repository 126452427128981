#Services {
  .card {
    background-color: #f5f9ff !important;
    border: none !important;
    border-radius: 8px;
    min-height: 152px;
  }

  .dot-icon {
    color: #f2555c !important;
    font-size: 10px;
  }

  .item-text {
    color: #39517a;
    font-weight: 500;
    font-size: 20px;
    padding: 10px;
    text-decoration: none !important;
  }

  .bp-text {
    color: #39517a;
  }
  .batch-description {
    font-size: 17px;
  }
}
