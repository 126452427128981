.__json-pretty__{line-height:1.3;color:white;background:#39517a;overflow:auto;padding-left:30px;}.__json-pretty__ .__json-key__{color:white}.__json-pretty__ .__json-value__{color:white}.__json-pretty__ .__json-string__{color:white}.__json-pretty__ .__json-boolean__{color:white}.__json-pretty-error__{line-height:1.3;color:white;background:#39517a;overflow:auto}

.example-json-container{
    overflow-y: scroll;
    max-height: 500px;
}

.item-text-ex{
    color: #39517a;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    text-decoration: none !important;
}

.example-p{
    color: #39517a;
    font-size: 20px;
    font-weight: 400;
}

.example-title{
    color: #39517a;
}

.example-subtitle{
    color: #f2555c !important;
}

.dot-icon-ex{
    color: #f2555c !important;
    font-size: 10px;
}

.example-background{
    background-color:#f5f9ff; 
}

.p-ex{
    padding-top: 20%;
}
.top-background{
    background-color:#f2efef !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background:  #39517a;
}

::-webkit-scrollbar-thumb:hover {
    background:  #4f70aa;
}

