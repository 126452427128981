#ContactInfo{
    .contact-info{
        color: white !important;
        padding-left: 10%;
    }

    .contact-font {
        font-size: 20px;
        padding-bottom: 100px;
    }

    .contact-icon {
        font-size: 25px;
    }
    .email-font{
        font-size: 20pt;
    }
}
