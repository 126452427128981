.send-message-container{
    background-color: white !important;
    border-radius: 20px;
    margin-top: 10%;
    margin-right: 80px;
    padding: 50px;
}

.form-text{
    color: #3a517b !important;
    font-weight: bold;
}
.send-btn{
    color: white !important;
    background-color: #f2555c !important;
    border-color: #f2555c !important;
    border-radius: none !important;
    padding: 10px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    border-radius: 0 !important;
    margin-top: 20px;    
}
