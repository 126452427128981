$blue-color: #39517a;
#ApiKeyContainer{    
    overflow: hidden;
    h5{
        color:$blue-color;
        font-weight: bold;
    }
    h2{
        color:$blue-color;
        font-weight: bold;
        margin-right:20px;
        margin-left:20px;
    }
    .key-span{        
        cursor: pointer;
        background-color: #f5f9ff;
        color: $blue-color;        
        padding: 5px;
        font-size: 13pt;
        overflow: visible;
        border-top: 1px solid $blue-color;
        border-bottom: 1px solid $blue-color;
        font-family:monospace;
    }
    .drop-down-btn{
        cursor: pointer;
        font-size: 15pt;
        background-color: #f5f9ff;
        // width: 25%;
        padding: 10px;
        text-align: center;
        border-radius: 10px;
        margin-top: 20px;
        color: $blue-color;
    }    
}
