#SideBar {
    .side-bar-body{
        background-color: #f2555c;
    }
    .header-img{
        margin-top: 10%;
        margin-bottom: 10%;
        width: 60%;
    }
    .menu-item{
        font-size: 15pt;
        font-weight: bold;
        text-align: left;
        padding-left: 20%;
        text-decoration: none !important;
        &:hover {
            background-color:#ff7d83;
        }
    }
    .custom-link{
        text-decoration: none !important;
    }

    .text-left {
        bottom: 10px;
        position: absolute;
        width: 350px;

        hr {
            margin-top: 0.5em;
            margin-bottom: 0.5rem;
            border-top: 1px solid rgba(0,0,0,.1)
        }
        .legal-btn {
            background-color: #f2555c;
            font-size: 10pt;
            font-weight: bold;
            text-align: center;
            border-color: #f2555c;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 50%;
            &:hover {
                background-color:#ff7d83;
            }
        }
        .signout-btn {
            background-color: #f2555c;
            font-size: 15pt;
            font-weight: bold;
            text-align: left;
            border-color: #f2555c;
            padding-left: 20%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
            &:hover {
                background-color:#ff7d83;
            }
        }
    }
}
