.file-drop {
    position: relative;
    height: 100px;
    width: 100%;
    margin: 20px;    
}

.file-drop > .file-drop-target {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
    border: none;
    background-color: #f2555d;
    box-shadow: none;
    z-index: 50;
    opacity: 1;    
    color: white;    
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    color: white;
    background-color: #ff7d8391;
}

.drop-container{   
    width: 100%; 
    color: rgb(255, 255, 255) !important;
    background-color: #39517a;
    padding-bottom: 3px;
    padding-top: 3px;   
}

.icon-container{
    font-size: 30pt;
}
