$blue-color: #39517a;
$red-color: #f2555c;
#Balance {
    padding-top: 5%;
    h3 {
        font-size: 25pt;
        font-weight: bold;
        color: $blue-color;
    }
    .card {
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
    }
    .col-6 {
        padding-right: 8%;
        padding-top: 20px;
    }
    .circular-progressbar {
    }
    .custom-font {
        font-size: 20px;
    }
}

#UsageReport{
    .report-item{
        border-bottom: 1px solid #39517a;
    }
    h2{
        font-weight: bold;
        color:$blue-color;
        text-align: left;
    }
    .card {
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
        font-size: 15pt;
    }
    .date{
        font-weight: bold;
    }
}
#Usage {    
    padding-top: 4%;    
    h3 {
        font-size: 25pt;
        font-weight: bold;
        color: $blue-color;
    }
    .col-12{
        padding-right: 5%;
        padding-top: 20px;
    }
    .card {
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
    }
}

#LastGroupsReport {
    .report-item {
        border-bottom: 1px solid #39517a;

        a {
            visibility: hidden;
        }

        &:hover {
            background-color: #d8e2f0;

            a {
                visibility: visible;
            }
        }
    }

    h2 {
        font-weight: bold;
        color: $blue-color;
        text-align: left;
    }

    .card {
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
        font-size: 15pt;
    }

    .date {
        font-weight: bold;
    }
}

#Account {
    z-index: 1000;
    background-color: #f5f9ff;
    padding-top: 4%;
    padding-bottom: 6%;
    padding-right: 25%;
    padding-left: 25%;
    margin-right: 10%;
    margin-top: 6%;
    border-radius: 15px;
    .account-container {
        background-color: white !important;
        border-radius: 20px;
        padding: 50px;
    }
    .sign-up-btn {
        color: $red-color;
        background-color: white;
        border-color: white;
        font-size: 15px;
        font-weight: bold;
    }
    .sign-in-btn {
        background-color: $red-color;
        color: white;
        width: 100%;
        border-color: $red-color;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0% !important;
        &:hover {
            background-color: #ff7d83;
        }
    }
    .form-control {
        height: 45px;
        color: $blue-color;
    }
    .form-label {
        color: $blue-color;
        font-weight: bold;
    }
    .form-group {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    h4 {
        color: $blue-color;
        font-weight: bold;
    }
    .text-label {
        color: $blue-color;
    }
}

