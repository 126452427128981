$blue-color: #39517a;
$red-color: #f2555c;

#PreviewContainer {
    padding-top: 50px;
    padding-left: 0%;
    h2 {
        color: $blue-color;
        font-weight: bold;
        padding-left: 20px;
    }
    tbody {
        width: 100%;
    }
    tr {
    }
    .preview-content {
        max-height: 300px;
        overflow-y: scroll;
    }
    th {
        display: none;
    }
    td {
        font-size: 15pt;
        padding: 20px;
        padding-right: 80px;
        padding-left: 80px;
        text-align: left;
        color: $blue-color;
        border-bottom: 1px solid rgba(57, 81, 122, 0.3);
    }
    .heading {
        display: none;
    }
}
