#NavBar {
    *:focus {outline:0 !important;}
    .logo {
        max-width: 300px;
        padding: 20px;
        padding-right: 50px;
    }
    
    .link-button {
        background-color: white !important;
        border-color: white !important;
        color: #f2555c !important;
        font-weight: bold !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        border-radius: 0 !important;
        &:focus {outline:0 !important;}
    }

    .sign-button {
        color: white !important;
        background-color: #f2555c !important;
        border-color: #f2555c !important;
        border-radius: 0 !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        &:hover {
            background-color:#ff7d83;
        }
    }

    .nav-bar-custom {
        background-color: white !important;
    }
}
