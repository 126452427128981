$blue-color: #39517a;
$red-color: #f2555c;

#Login {
    z-index: 1000;
    background-color: #f5f9ff;
    padding-top: 4%;
    padding-bottom: 6%;
    padding-right: 25%;
    padding-left: 25%;
    .login-container {
    background-color: white !important;
    border-radius: 20px;
    padding: 50px;
    }
    .sign-up-btn {
        color: $red-color;
        background-color: white;
        border-color: white;
        font-size: 15px;
        font-weight: bold;
    }
    .sign-in-btn {
        background-color: $red-color;
        color: white;
        width: 100%;
        border-color: $red-color;
        font-size: 15px;
        font-weight: bold;
        border-radius: 0% !important;
        &:hover {
            background-color: #ff7d83;
        }
    }
    .form-control {
        height: 45px;
        color: $blue-color;
    }
    .form-label {
        color: $blue-color;
        font-weight: bold;
    }
    .form-group {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    h4 {
        color: $blue-color;
        font-weight: bold;
    }
    .text-label {
        color: $blue-color;
    }
    .text-not-valid{color:$red-color !important;}
    .border-not-valid{
        color:$red-color !important;
        border-color: $red-color !important;
    }
}
