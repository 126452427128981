#APISideBar {
    .side-bar-body{
        background-color: #39517a;
    }
    .header-img{
        margin-top: 10%;
        margin-bottom: 10%;
        width: 60%;
    }
    .menu-item{
        color: rgb(197, 197, 197);
        font-size: 15pt;
        font-weight: bold;
        text-align: left;
        padding-left: 20%;
        text-decoration: none !important;
        &:hover {
            background-color:#4b689b;
            color: white;
        }
        cursor: pointer;
    }
    .menu-item-secu{
        color: rgb(197, 197, 197);
        font-size: 13pt;
        font-weight: bold;
        text-align: left;
        padding-left: 30%;
        text-decoration: none !important;
        &:hover {
            background-color:#4b689b;
            color: white;
        }
        cursor: pointer;
    }
    .custom-link{
        text-decoration: none !important;
    }
}
