$blue-color: #3a517b;
$red-color: #f2555c;

#LiveDemo{    
    h3{
        text-align: center;
        color: $blue-color;
        font-weight: bold;
        padding-top: 30px;
    }
    .live-demo-container {
        color: $blue-color !important;
    }
    .demo-title{
        color: $red-color !important;
        font-weight: bold;
    }
    .card {
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
    }
}

.DemoResults{
    max-height: 300px;
    overflow-y: scroll;
    .title{
        padding-right: 12px;
        color:$red-color;
        font-size: 20px;
        font-weight: bold;        
    }
    .result{
        color:$blue-color;
        font-size: 20px;
        font-weight: bold;       
    }
}