#DemoFormContainer {    
    p,.demo-form-container,.form-label {
        color: #3a517b !important;
    }

    .try-btn {
        color: white !important;
        background-color: #f2555c !important;
        border-color: #f2555c !important;
        border-radius: none !important;
        font-size: 15px !important;
        font-weight: bold !important;
        padding-right: 30px !important;
        padding-left: 30px !important;
        border-radius: 0 !important;
    }
}
