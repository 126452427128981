$blue-color: #39517a;
$orange-color: #f29f05;
.__json-pretty__{line-height:1.3;color:white;background:#39517a;overflow:auto;padding-left:50px;}.__json-pretty__ .__json-key__{color:$orange-color}.__json-pretty__ .__json-value__{color:white}.__json-pretty__ .__json-string__{color:$orange-color}.__json-pretty__ .__json-boolean__{color:white}.__json-pretty-error__{line-height:1.3;color:white;background:#39517a;overflow:auto}
#APIContent{   
    margin-top: -110px;    
    background-color: white;   
    margin-left: 0px;
    padding-left: 20px;
    .row-c{
        margin-right: -80px !important;
    }    
    h1,h2,h3,h6{
        color: $blue-color !important;
        font-weight: bold;
    }
    h3{
        padding: 20px;
        padding-left: 0 !important;
    }
    p{
        color: $blue-color !important;
        font-size: 15px;
        font-weight: 500;
    }
    .card{
        padding: 15px;
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 10px;
        font-weight: 700 !important;
        font-family:monospace;
    }
    .right-container{
        background-color: $blue-color;
        margin-right: 0 !important;
        padding-right: 0 !important;
        min-height: 50px;
    } 
    .small-code{       
        background-color: #f5f9ff;
        border-color: #f5f9ff;
        color: $blue-color;
        border-radius: 5px;
        font-weight: 700 !important;
        font-family:monospace;        
        width: fit-content;
        padding-right: 5px;
        padding-left: 5px;
    }
    .right-title{
        color:white;
        font-size: 20pt;
        font-weight: 600;
        background-color: #374b6d;
        padding: 20px;
        padding-left: 35px;
    }
    .content-div{
        padding-top: 10px;
        padding-bottom: 10px;
        color: $blue-color;
        border-bottom: 1px solid #374b6d31;
    }
    table {
        table-layout:fixed;
        width: 100%;
        thead {            
            tr th {
                width: 100%;
                border: 0;
                color: $blue-color !important;
                font-weight: bold;
            }
        }
        tbody {
            tr {
                td {
                    width: 100%;
                    border-top: 0;
                    color: $blue-color;
                    border-bottom: 1px solid #374b6d31;
                }
            }
        }
    }
}
