.presentation-container{
    padding: 5%;
    padding-top: 20%;
    padding-bottom: 10%;
}
.title{
    color: white;
}

.description{
    color: white;
    font-size: 22px;
}

.live-demo-btn{
    color: white !important;
    background-color: #f2555c !important;
    border-color: #f2555c !important;
    border-radius: none !important;
    padding: 15px !important;
    font-size: 22px !important;
    font-weight: bold !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    border-radius: 0 !important;    
}
