#TermsOfUse {
    

    ol {
        margin: 0;
        padding: 0
    }
    table td,
    table th {
        padding: 0
    }

    .c2 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 18pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.5;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        margin-left: 36pt;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        text-align: justify;
        padding-right: 0pt
    }

    .c12 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c3 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.5;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

    .c10 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }

    .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-style: normal
    }


    .c1 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.5;
        text-align: justify
    }

    .c5 {
        font-weight: 400
    }

    &.mainContent {
        background-color: #ffffff;
        margin: 0 3rem;
    }

    .c4 {
        font-weight: 700
    }

    .c9 {
        padding: 0;
        margin: 0
    }

    .c8 {
        margin-left: 18pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
    }

    p {
        margin: 0;
        color: #000000;
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    ol.lst-kix_91uemewk7k2f-0.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-0 0
    }

    ol.lst-kix_91uemewk7k2f-7.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-7 0
    }

    .lst-kix_91uemewk7k2f-4>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-4
    }

    ol.lst-kix_8267syw2sud7-7.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-7 0
    }

    .lst-kix_8267syw2sud7-1>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-1
    }

    .lst-kix_8267syw2sud7-7>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-7
    }

    .lst-kix_91uemewk7k2f-5>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-5
    }

    ol.lst-kix_8267syw2sud7-4.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-4 0
    }

    .lst-kix_91uemewk7k2f-0>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-0
    }

    .lst-kix_91uemewk7k2f-3>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-3
    }

    .lst-kix_91uemewk7k2f-3>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-3, decimal) ". "
    }

    .lst-kix_91uemewk7k2f-5>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-5, lower-roman) ". "
    }

    .lst-kix_91uemewk7k2f-2>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-2, lower-roman) ". "
    }

    .lst-kix_91uemewk7k2f-6>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-6, decimal) ". "
    }

    .lst-kix_91uemewk7k2f-1>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-1, lower-latin) ". "
    }

    .lst-kix_91uemewk7k2f-7>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-7, lower-latin) ". "
    }

    .lst-kix_91uemewk7k2f-6>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-6
    }

    .lst-kix_91uemewk7k2f-0>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-0, lower-roman) ") "
    }

    .lst-kix_91uemewk7k2f-8>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-8, lower-roman) ". "
    }

    .lst-kix_8267syw2sud7-8>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-8
    }

    ol.lst-kix_91uemewk7k2f-3.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-3 0
    }

    .lst-kix_8267syw2sud7-5>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-5
    }

    ol.lst-kix_8267syw2sud7-8.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-8 0
    }

    ol.lst-kix_91uemewk7k2f-0 {
        list-style-type: none
    }

    .lst-kix_8267syw2sud7-2>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-2
    }

    ol.lst-kix_91uemewk7k2f-2 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-1 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-4 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-3 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-6 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-5 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-8 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-7 {
        list-style-type: none
    }

    .lst-kix_91uemewk7k2f-4>li:before {
        content: ""counter(lst-ctn-kix_91uemewk7k2f-4, lower-latin) ". "
    }

    ol.lst-kix_91uemewk7k2f-6.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-6 0
    }

    .lst-kix_8267syw2sud7-2>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-2, lower-roman) ". "
    }

    ol.lst-kix_8267syw2sud7-5.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-5 0
    }

    .lst-kix_8267syw2sud7-1>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-1, lower-latin) ". "
    }

    .lst-kix_8267syw2sud7-3>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-3, decimal) ". "
    }

    ol.lst-kix_91uemewk7k2f-5.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-5 0
    }

    .lst-kix_8267syw2sud7-6>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-6, decimal) ". "
    }

    .lst-kix_8267syw2sud7-5>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-5, lower-roman) ". "
    }

    .lst-kix_8267syw2sud7-4>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-4, lower-latin) ". "
    }

    .lst-kix_91uemewk7k2f-1>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-1
    }

    ol.lst-kix_8267syw2sud7-2.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-2 0
    }

    .lst-kix_91uemewk7k2f-8>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-8
    }

    ol.lst-kix_8267syw2sud7-1.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-1 0
    }

    .lst-kix_8267syw2sud7-4>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-4
    }

    .lst-kix_8267syw2sud7-7>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-7, lower-latin) ". "
    }

    ol.lst-kix_91uemewk7k2f-2.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-2 0
    }

    .lst-kix_8267syw2sud7-8>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-8, lower-roman) ". "
    }

    .lst-kix_91uemewk7k2f-2>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-2
    }

    ol.lst-kix_8267syw2sud7-1 {
        list-style-type: none
    }

    ol.lst-kix_8267syw2sud7-0 {
        list-style-type: none
    }

    .lst-kix_8267syw2sud7-0>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-0
    }

    ol.lst-kix_8267syw2sud7-5 {
        list-style-type: none
    }

    ol.lst-kix_8267syw2sud7-4 {
        list-style-type: none
    }

    .lst-kix_8267syw2sud7-6>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-6
    }

    ol.lst-kix_8267syw2sud7-3 {
        list-style-type: none
    }

    ol.lst-kix_8267syw2sud7-2 {
        list-style-type: none
    }

    ol.lst-kix_8267syw2sud7-6.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-6 0
    }

    ol.lst-kix_91uemewk7k2f-8.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-8 0
    }

    .lst-kix_8267syw2sud7-3>li {
        counter-increment: lst-ctn-kix_8267syw2sud7-3
    }

    li.li-bullet-0:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    ol.lst-kix_91uemewk7k2f-4.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-4 0
    }

    ol.lst-kix_8267syw2sud7-3.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-3 0
    }

    .lst-kix_91uemewk7k2f-7>li {
        counter-increment: lst-ctn-kix_91uemewk7k2f-7
    }

    ol.lst-kix_8267syw2sud7-8 {
        list-style-type: none
    }

    ol.lst-kix_91uemewk7k2f-1.start {
        counter-reset: lst-ctn-kix_91uemewk7k2f-1 0
    }

    ol.lst-kix_8267syw2sud7-7 {
        list-style-type: none
    }

    ol.lst-kix_8267syw2sud7-6 {
        list-style-type: none
    }

    .lst-kix_8267syw2sud7-0>li:before {
        content: ""counter(lst-ctn-kix_8267syw2sud7-0, lower-roman) ") "
    }

    ol.lst-kix_8267syw2sud7-0.start {
        counter-reset: lst-ctn-kix_8267syw2sud7-0 0
    }
}