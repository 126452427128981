.presentation-container{
    padding: 5%;
    padding-top: 20%;
    padding-bottom: 10%;
}
.title{
    color: white;
}

.description{
    color: white;
    font-size: 22px;
}

.live-demo-btn{
    color: white !important;
    background-color: #f2555c !important;
    border-color: #f2555c !important;
    border-radius: none !important;
    padding: 15px !important;
    font-size: 22px !important;
    font-weight: bold !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    border-radius: 0 !important;    
}

.map-image{
    width: 600px;
    height: 600px;
    object-fit: cover !important;
    object-position: 100% 20%;      
    border-radius: 100%;  
}

.marker{
    color: #f2555c !important;
    font-size: 70px;    
    position: absolute;
    left: 52%;
    top: 30%;
}

.map-container{
    padding-left: 25%;
    padding-top: 5%;
    padding-bottom: 5%;
}

#Services .card {
  background-color: #f5f9ff !important;
  border: none !important;
  border-radius: 8px;
  min-height: 152px; }

#Services .dot-icon {
  color: #f2555c !important;
  font-size: 10px; }

#Services .item-text {
  color: #39517a;
  font-weight: 500;
  font-size: 20px;
  padding: 10px;
  text-decoration: none !important; }

#Services .bp-text {
  color: #39517a; }

#Services .batch-description {
  font-size: 17px; }

.__json-pretty__{line-height:1.3;color:white;background:#39517a;overflow:auto;padding-left:30px;}.__json-pretty__ .__json-key__{color:white}.__json-pretty__ .__json-value__{color:white}.__json-pretty__ .__json-string__{color:white}.__json-pretty__ .__json-boolean__{color:white}.__json-pretty-error__{line-height:1.3;color:white;background:#39517a;overflow:auto}

.example-json-container{
    overflow-y: scroll;
    max-height: 500px;
}

.item-text-ex{
    color: #39517a;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    text-decoration: none !important;
}

.example-p{
    color: #39517a;
    font-size: 20px;
    font-weight: 400;
}

.example-title{
    color: #39517a;
}

.example-subtitle{
    color: #f2555c !important;
}

.dot-icon-ex{
    color: #f2555c !important;
    font-size: 10px;
}

.example-background{
    background-color:#f5f9ff; 
}

.p-ex{
    padding-top: 20%;
}
.top-background{
    background-color:#f2efef !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background:  #39517a;
}

::-webkit-scrollbar-thumb:hover {
    background:  #4f70aa;
}


#DemoFormContainer p, #DemoFormContainer .demo-form-container, #DemoFormContainer .form-label {
  color: #3a517b !important; }

#DemoFormContainer .try-btn {
  color: white !important;
  background-color: #f2555c !important;
  border-color: #f2555c !important;
  border-radius: none !important;
  font-size: 15px !important;
  font-weight: bold !important;
  padding-right: 30px !important;
  padding-left: 30px !important;
  border-radius: 0 !important; }

#LiveDemo h3 {
  text-align: center;
  color: #3a517b;
  font-weight: bold;
  padding-top: 30px; }

#LiveDemo .live-demo-container {
  color: #3a517b !important; }

#LiveDemo .demo-title {
  color: #f2555c !important;
  font-weight: bold; }

#LiveDemo .card {
  padding: 15px;
  background-color: #f5f9ff;
  border-color: #f5f9ff;
  color: #3a517b;
  border-radius: 10px; }

.DemoResults {
  max-height: 300px;
  overflow-y: scroll; }
  .DemoResults .title {
    padding-right: 12px;
    color: #f2555c;
    font-size: 20px;
    font-weight: bold; }
  .DemoResults .result {
    color: #3a517b;
    font-size: 20px;
    font-weight: bold; }

.home-page-container {
    background-color: #3a517b !important;    
}

#NavBar *:focus {
  outline: 0 !important; }

#NavBar .logo {
  max-width: 300px;
  padding: 20px;
  padding-right: 50px; }

#NavBar .link-button {
  background-color: white !important;
  border-color: white !important;
  color: #f2555c !important;
  font-weight: bold !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 0 !important; }
  #NavBar .link-button:focus {
    outline: 0 !important; }

#NavBar .sign-button {
  color: white !important;
  background-color: #f2555c !important;
  border-color: #f2555c !important;
  border-radius: 0 !important;
  padding-left: 40px !important;
  padding-right: 40px !important; }
  #NavBar .sign-button:hover {
    background-color: #ff7d83; }

#NavBar .nav-bar-custom {
  background-color: white !important; }

#SideBar .side-bar-body {
  background-color: #f2555c; }

#SideBar .header-img {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 60%; }

#SideBar .menu-item {
  font-size: 15pt;
  font-weight: bold;
  text-align: left;
  padding-left: 20%;
  text-decoration: none !important; }
  #SideBar .menu-item:hover {
    background-color: #ff7d83; }

#SideBar .custom-link {
  text-decoration: none !important; }

#SideBar .text-left {
  bottom: 10px;
  position: absolute;
  width: 350px; }
  #SideBar .text-left hr {
    margin-top: 0.5em;
    margin-bottom: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  #SideBar .text-left .legal-btn {
    background-color: #f2555c;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
    border-color: #f2555c;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 50%; }
    #SideBar .text-left .legal-btn:hover {
      background-color: #ff7d83; }
  #SideBar .text-left .signout-btn {
    background-color: #f2555c;
    font-size: 15pt;
    font-weight: bold;
    text-align: left;
    border-color: #f2555c;
    padding-left: 20%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%; }
    #SideBar .text-left .signout-btn:hover {
      background-color: #ff7d83; }

.send-message-container{
    background-color: white !important;
    border-radius: 20px;
    margin-top: 10%;
    margin-right: 80px;
    padding: 50px;
}

.form-text{
    color: #3a517b !important;
    font-weight: bold;
}
.send-btn{
    color: white !important;
    background-color: #f2555c !important;
    border-color: #f2555c !important;
    border-radius: none !important;
    padding: 10px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    border-radius: 0 !important;
    margin-top: 20px;    
}

#ContactInfo .contact-info {
  color: white !important;
  padding-left: 10%; }

#ContactInfo .contact-font {
  font-size: 20px;
  padding-bottom: 100px; }

#ContactInfo .contact-icon {
  font-size: 25px; }

#ContactInfo .email-font {
  font-size: 20pt; }

.contact-background{
    background-color: #3a517b !important;
}

.contact-info-container{
    padding: 100px;
}


#APISideBar .side-bar-body {
  background-color: #39517a; }

#APISideBar .header-img {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 60%; }

#APISideBar .menu-item {
  color: #c5c5c5;
  font-size: 15pt;
  font-weight: bold;
  text-align: left;
  padding-left: 20%;
  text-decoration: none !important;
  cursor: pointer; }
  #APISideBar .menu-item:hover {
    background-color: #4b689b;
    color: white; }

#APISideBar .menu-item-secu {
  color: #c5c5c5;
  font-size: 13pt;
  font-weight: bold;
  text-align: left;
  padding-left: 30%;
  text-decoration: none !important;
  cursor: pointer; }
  #APISideBar .menu-item-secu:hover {
    background-color: #4b689b;
    color: white; }

#APISideBar .custom-link {
  text-decoration: none !important; }

.__json-pretty__ {
  line-height: 1.3;
  color: white;
  background: #39517a;
  overflow: auto;
  padding-left: 50px; }

.__json-pretty__ .__json-key__ {
  color: #f29f05; }

.__json-pretty__ .__json-value__ {
  color: white; }

.__json-pretty__ .__json-string__ {
  color: #f29f05; }

.__json-pretty__ .__json-boolean__ {
  color: white; }

.__json-pretty-error__ {
  line-height: 1.3;
  color: white;
  background: #39517a;
  overflow: auto; }

#APIContent {
  margin-top: -110px;
  background-color: white;
  margin-left: 0px;
  padding-left: 20px; }
  #APIContent .row-c {
    margin-right: -80px !important; }
  #APIContent h1, #APIContent h2, #APIContent h3, #APIContent h6 {
    color: #39517a !important;
    font-weight: bold; }
  #APIContent h3 {
    padding: 20px;
    padding-left: 0 !important; }
  #APIContent p {
    color: #39517a !important;
    font-size: 15px;
    font-weight: 500; }
  #APIContent .card {
    padding: 15px;
    background-color: #f5f9ff;
    border-color: #f5f9ff;
    color: #39517a;
    border-radius: 10px;
    font-weight: 700 !important;
    font-family: monospace; }
  #APIContent .right-container {
    background-color: #39517a;
    margin-right: 0 !important;
    padding-right: 0 !important;
    min-height: 50px; }
  #APIContent .small-code {
    background-color: #f5f9ff;
    border-color: #f5f9ff;
    color: #39517a;
    border-radius: 5px;
    font-weight: 700 !important;
    font-family: monospace;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 5px;
    padding-left: 5px; }
  #APIContent .right-title {
    color: white;
    font-size: 20pt;
    font-weight: 600;
    background-color: #374b6d;
    padding: 20px;
    padding-left: 35px; }
  #APIContent .content-div {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #39517a;
    border-bottom: 1px solid #374b6d31; }
  #APIContent table {
    table-layout: fixed;
    width: 100%; }
    #APIContent table thead tr th {
      width: 100%;
      border: 0;
      color: #39517a !important;
      font-weight: bold; }
    #APIContent table tbody tr td {
      width: 100%;
      border-top: 0;
      color: #39517a;
      border-bottom: 1px solid #374b6d31; }

#Login {
  z-index: 1000;
  background-color: #f5f9ff;
  padding-top: 4%;
  padding-bottom: 6%;
  padding-right: 25%;
  padding-left: 25%; }
  #Login .login-container {
    background-color: white !important;
    border-radius: 20px;
    padding: 50px; }
  #Login .sign-up-btn {
    color: #f2555c;
    background-color: white;
    border-color: white;
    font-size: 15px;
    font-weight: bold; }
  #Login .sign-in-btn {
    background-color: #f2555c;
    color: white;
    width: 100%;
    border-color: #f2555c;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0% !important; }
    #Login .sign-in-btn:hover {
      background-color: #ff7d83; }
  #Login .form-control {
    height: 45px;
    color: #39517a; }
  #Login .form-label {
    color: #39517a;
    font-weight: bold; }
  #Login .form-group {
    padding-bottom: 20px;
    padding-top: 20px; }
  #Login h4 {
    color: #39517a;
    font-weight: bold; }
  #Login .text-label {
    color: #39517a; }
  #Login .text-not-valid {
    color: #f2555c !important; }
  #Login .border-not-valid {
    color: #f2555c !important;
    border-color: #f2555c !important; }

#Pricing {
  background-color: #f5f9ff; }
  #Pricing h1 {
    color: #39517a;
    font-weight: bold;
    text-align: center;
    font-size: 25pt; }
  #Pricing h2 {
    color: #39517a !important;
    font-style: italic;
    font-weight: bold;
    font-size: 12pt; }
  #Pricing p {
    color: #39517a; }
  #Pricing .pricing-text {
    padding: 100px;
    color: #39517a;
    font-size: 15pt; }
  #Pricing .center-text {
    padding-top: 130px !important; }
  #Pricing .title {
    padding-top: 30px; }
  #Pricing .card-icon {
    font-size: 35pt;
    color: #f2555c; }
  #Pricing .sign-up-btn {
    background-color: #f2555c;
    color: white;
    border-color: #f2555c;
    font-size: 15pt;
    font-weight: bold;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0% !important; }
    #Pricing .sign-up-btn:hover {
      background-color: #ff7d83; }
  #Pricing .btn-container {
    padding-top: 80px;
    padding-bottom: 100px; }

.CircularProgressbar-path {
    stroke: #39517a !important;
}


#Balance {
  padding-top: 5%; }
  #Balance h3 {
    font-size: 25pt;
    font-weight: bold;
    color: #39517a; }
  #Balance .card {
    padding: 15px;
    background-color: #f5f9ff;
    border-color: #f5f9ff;
    color: #39517a;
    border-radius: 10px; }
  #Balance .col-6 {
    padding-right: 8%;
    padding-top: 20px; }
  #Balance .custom-font {
    font-size: 20px; }

#UsageReport .report-item {
  border-bottom: 1px solid #39517a; }

#UsageReport h2 {
  font-weight: bold;
  color: #39517a;
  text-align: left; }

#UsageReport .card {
  padding: 15px;
  background-color: #f5f9ff;
  border-color: #f5f9ff;
  color: #39517a;
  border-radius: 10px;
  font-size: 15pt; }

#UsageReport .date {
  font-weight: bold; }

#Usage {
  padding-top: 4%; }
  #Usage h3 {
    font-size: 25pt;
    font-weight: bold;
    color: #39517a; }
  #Usage .col-12 {
    padding-right: 5%;
    padding-top: 20px; }
  #Usage .card {
    padding: 15px;
    background-color: #f5f9ff;
    border-color: #f5f9ff;
    color: #39517a;
    border-radius: 10px; }

#LastGroupsReport .report-item {
  border-bottom: 1px solid #39517a; }
  #LastGroupsReport .report-item a {
    visibility: hidden; }
  #LastGroupsReport .report-item:hover {
    background-color: #d8e2f0; }
    #LastGroupsReport .report-item:hover a {
      visibility: visible; }

#LastGroupsReport h2 {
  font-weight: bold;
  color: #39517a;
  text-align: left; }

#LastGroupsReport .card {
  padding: 15px;
  background-color: #f5f9ff;
  border-color: #f5f9ff;
  color: #39517a;
  border-radius: 10px;
  font-size: 15pt; }

#LastGroupsReport .date {
  font-weight: bold; }

#Account {
  z-index: 1000;
  background-color: #f5f9ff;
  padding-top: 4%;
  padding-bottom: 6%;
  padding-right: 25%;
  padding-left: 25%;
  margin-right: 10%;
  margin-top: 6%;
  border-radius: 15px; }
  #Account .account-container {
    background-color: white !important;
    border-radius: 20px;
    padding: 50px; }
  #Account .sign-up-btn {
    color: #f2555c;
    background-color: white;
    border-color: white;
    font-size: 15px;
    font-weight: bold; }
  #Account .sign-in-btn {
    background-color: #f2555c;
    color: white;
    width: 100%;
    border-color: #f2555c;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0% !important; }
    #Account .sign-in-btn:hover {
      background-color: #ff7d83; }
  #Account .form-control {
    height: 45px;
    color: #39517a; }
  #Account .form-label {
    color: #39517a;
    font-weight: bold; }
  #Account .form-group {
    padding-bottom: 20px;
    padding-top: 20px; }
  #Account h4 {
    color: #39517a;
    font-weight: bold; }
  #Account .text-label {
    color: #39517a; }

#ApiKeyContainer {
  overflow: hidden; }
  #ApiKeyContainer h5 {
    color: #39517a;
    font-weight: bold; }
  #ApiKeyContainer h2 {
    color: #39517a;
    font-weight: bold;
    margin-right: 20px;
    margin-left: 20px; }
  #ApiKeyContainer .key-span {
    cursor: pointer;
    background-color: #f5f9ff;
    color: #39517a;
    padding: 5px;
    font-size: 13pt;
    overflow: visible;
    border-top: 1px solid #39517a;
    border-bottom: 1px solid #39517a;
    font-family: monospace; }
  #ApiKeyContainer .drop-down-btn {
    cursor: pointer;
    font-size: 15pt;
    background-color: #f5f9ff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    margin-top: 20px;
    color: #39517a; }

#BatchProccesorContainer {
  padding-top: 5%;
  padding-bottom: 20px; }
  #BatchProccesorContainer h1 {
    color: #39517a;
    font-weight: bold;
    text-align: left;
    font-size: 25pt; }
  #BatchProccesorContainer h2 {
    color: #39517a;
    font-weight: bold;
    text-align: left; }
  #BatchProccesorContainer .appends-title {
    padding-top: 50px; }
  #BatchProccesorContainer .file-loader {
    text-align: center;
    padding-top: 20px;
    padding-right: 20px; }
  #BatchProccesorContainer .check-container {
    color: #39517a;
    font-weight: bold;
    padding: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    margin: 20px;
    margin-left: 1;
    background-color: white;
    border-radius: 20px;
    height: 45px;
    border: 1px solid rgba(57, 81, 122, 0.3); }
  #BatchProccesorContainer .btn, #BatchProccesorContainer button {
    color: white !important;
    font-size: 14pt;
    font-weight: bold;
    background-color: #39517a !important;
    border-color: #39517a !important;
    border-radius: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    border-width: 0;
    box-shadow: none;
    margin-right: 20px;
    margin-left: 20px; }
    #BatchProccesorContainer .btn:hover, #BatchProccesorContainer button:hover {
      background-color: #557ab9; }
  #BatchProccesorContainer .btn {
    margin-top: -8px; }
  #BatchProccesorContainer span button {
    padding: 5px; }
  #BatchProccesorContainer .metadataBox {
    padding: 20px;
    border-radius: 40px;
    background-color: #e1e6ec;
    text-align: center;
    border: 20px solid #fff; }
    #BatchProccesorContainer .metadataBox:hover {
      background-color: #d8e2f0; }
    #BatchProccesorContainer .metadataBox h1 {
      text-align: center;
      font-size: 30pt; }
    #BatchProccesorContainer .metadataBox span {
      font-size: 15pt; }

.file-drop {
    position: relative;
    height: 100px;
    width: 100%;
    margin: 20px;    
}

.file-drop > .file-drop-target {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;

    display: flex;
    display: -ms-flexbox;

    flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
    border: none;
    background-color: #f2555d;
    box-shadow: none;
    z-index: 50;
    opacity: 1;    
    color: white;    
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
    color: white;
    background-color: #ff7d8391;
}

.drop-container{   
    width: 100%; 
    color: rgb(255, 255, 255) !important;
    background-color: #39517a;
    padding-bottom: 3px;
    padding-top: 3px;   
}

.icon-container{
    font-size: 30pt;
}

#PreviewContainer {
  padding-top: 50px;
  padding-left: 0%; }
  #PreviewContainer h2 {
    color: #39517a;
    font-weight: bold;
    padding-left: 20px; }
  #PreviewContainer tbody {
    width: 100%; }
  #PreviewContainer .preview-content {
    max-height: 300px;
    overflow-y: scroll; }
  #PreviewContainer th {
    display: none; }
  #PreviewContainer td {
    font-size: 15pt;
    padding: 20px;
    padding-right: 80px;
    padding-left: 80px;
    text-align: left;
    color: #39517a;
    border-bottom: 1px solid rgba(57, 81, 122, 0.3); }
  #PreviewContainer .heading {
    display: none; }

#Footer *:focus {
  outline: 0 !important; }

#Footer .link-button {
  background-color: white !important;
  border-color: white !important;
  color: #f2555c !important;
  font-weight: bold !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  border-radius: 0 !important; }
  #Footer .link-button:focus {
    outline: 0 !important; }

#TermsOfUse ol {
  margin: 0;
  padding: 0; }

#TermsOfUse table td,
#TermsOfUse table th {
  padding: 0; }

#TermsOfUse .c2 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 18pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 36pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#TermsOfUse .c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#TermsOfUse .c3 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#TermsOfUse .c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt; }

#TermsOfUse .c10 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#TermsOfUse .c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#TermsOfUse .c1 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify; }

#TermsOfUse .c5 {
  font-weight: 400; }

#TermsOfUse.mainContent {
  background-color: #ffffff;
  margin: 0 3rem; }

#TermsOfUse .c4 {
  font-weight: 700; }

#TermsOfUse .c9 {
  padding: 0;
  margin: 0; }

#TermsOfUse .c8 {
  margin-left: 18pt; }

#TermsOfUse .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse li {
  color: #000000; }

#TermsOfUse p {
  margin: 0;
  color: #000000; }

#TermsOfUse h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-0.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-0 0; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-7.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-7 0; }

#TermsOfUse .lst-kix_91uemewk7k2f-4 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-4; }

#TermsOfUse ol.lst-kix_8267syw2sud7-7.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-7 0; }

#TermsOfUse .lst-kix_8267syw2sud7-1 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-1; }

#TermsOfUse .lst-kix_8267syw2sud7-7 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-7; }

#TermsOfUse .lst-kix_91uemewk7k2f-5 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-5; }

#TermsOfUse ol.lst-kix_8267syw2sud7-4.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-4 0; }

#TermsOfUse .lst-kix_91uemewk7k2f-0 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-0; }

#TermsOfUse .lst-kix_91uemewk7k2f-3 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-3; }

#TermsOfUse .lst-kix_91uemewk7k2f-3 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-3, decimal) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-5 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-5, lower-roman) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-2 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-2, lower-roman) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-6 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-6, decimal) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-1 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-1, lower-latin) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-7 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-7, lower-latin) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-6 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-6; }

#TermsOfUse .lst-kix_91uemewk7k2f-0 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-0, lower-roman) ") "; }

#TermsOfUse .lst-kix_91uemewk7k2f-8 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-8, lower-roman) ". "; }

#TermsOfUse .lst-kix_8267syw2sud7-8 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-8; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-3.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-3 0; }

#TermsOfUse .lst-kix_8267syw2sud7-5 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-5; }

#TermsOfUse ol.lst-kix_8267syw2sud7-8.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-8 0; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-0 {
  list-style-type: none; }

#TermsOfUse .lst-kix_8267syw2sud7-2 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-2; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-2 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-1 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-4 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-3 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-6 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-5 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-8 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-7 {
  list-style-type: none; }

#TermsOfUse .lst-kix_91uemewk7k2f-4 > li:before {
  content: "" counter(lst-ctn-kix_91uemewk7k2f-4, lower-latin) ". "; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-6.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-6 0; }

#TermsOfUse .lst-kix_8267syw2sud7-2 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-2, lower-roman) ". "; }

#TermsOfUse ol.lst-kix_8267syw2sud7-5.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-5 0; }

#TermsOfUse .lst-kix_8267syw2sud7-1 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-1, lower-latin) ". "; }

#TermsOfUse .lst-kix_8267syw2sud7-3 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-3, decimal) ". "; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-5.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-5 0; }

#TermsOfUse .lst-kix_8267syw2sud7-6 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-6, decimal) ". "; }

#TermsOfUse .lst-kix_8267syw2sud7-5 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-5, lower-roman) ". "; }

#TermsOfUse .lst-kix_8267syw2sud7-4 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-4, lower-latin) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-1 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-1; }

#TermsOfUse ol.lst-kix_8267syw2sud7-2.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-2 0; }

#TermsOfUse .lst-kix_91uemewk7k2f-8 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-8; }

#TermsOfUse ol.lst-kix_8267syw2sud7-1.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-1 0; }

#TermsOfUse .lst-kix_8267syw2sud7-4 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-4; }

#TermsOfUse .lst-kix_8267syw2sud7-7 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-7, lower-latin) ". "; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-2.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-2 0; }

#TermsOfUse .lst-kix_8267syw2sud7-8 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-8, lower-roman) ". "; }

#TermsOfUse .lst-kix_91uemewk7k2f-2 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-2; }

#TermsOfUse ol.lst-kix_8267syw2sud7-1 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_8267syw2sud7-0 {
  list-style-type: none; }

#TermsOfUse .lst-kix_8267syw2sud7-0 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-0; }

#TermsOfUse ol.lst-kix_8267syw2sud7-5 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_8267syw2sud7-4 {
  list-style-type: none; }

#TermsOfUse .lst-kix_8267syw2sud7-6 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-6; }

#TermsOfUse ol.lst-kix_8267syw2sud7-3 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_8267syw2sud7-2 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_8267syw2sud7-6.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-6 0; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-8.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-8 0; }

#TermsOfUse .lst-kix_8267syw2sud7-3 > li {
  counter-increment: lst-ctn-kix_8267syw2sud7-3; }

#TermsOfUse li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-4.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-4 0; }

#TermsOfUse ol.lst-kix_8267syw2sud7-3.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-3 0; }

#TermsOfUse .lst-kix_91uemewk7k2f-7 > li {
  counter-increment: lst-ctn-kix_91uemewk7k2f-7; }

#TermsOfUse ol.lst-kix_8267syw2sud7-8 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_91uemewk7k2f-1.start {
  counter-reset: lst-ctn-kix_91uemewk7k2f-1 0; }

#TermsOfUse ol.lst-kix_8267syw2sud7-7 {
  list-style-type: none; }

#TermsOfUse ol.lst-kix_8267syw2sud7-6 {
  list-style-type: none; }

#TermsOfUse .lst-kix_8267syw2sud7-0 > li:before {
  content: "" counter(lst-ctn-kix_8267syw2sud7-0, lower-roman) ") "; }

#TermsOfUse ol.lst-kix_8267syw2sud7-0.start {
  counter-reset: lst-ctn-kix_8267syw2sud7-0 0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-3.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-3 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-4 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-4; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-4 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-6 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-2.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-2 0; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-7.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-7 0; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-3 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-3, decimal) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-7 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-4.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-4 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-4 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-4; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-2 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-8 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-8; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-5 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-7 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-7; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-6.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-6 0; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-8 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-7 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-7; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-3 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-3; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-0.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-0 0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-0 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-0; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-6 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-6 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-6; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-0.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-0 0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-8.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-8 0; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-0.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-0 0; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-0 > li:before {
  content: "3." counter(lst-ctn-kix_qxl1aouwtadn-0, decimal) " "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-2 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-1 > li:before {
  content: "" counter(lst-ctn-kix_qxl1aouwtadn-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-0 > li:before {
  content: "9." counter(lst-ctn-kix_f1ff1elfy1yp-0, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-2.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-2 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-1 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-5.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-5 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-2 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-2, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-6.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-6 0; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-7.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-7 0; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-0 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-4.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-4 0; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-2 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-2; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-7 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-7; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-5.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-5 0; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-2 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-2; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-7 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-7; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-8 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-8; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-1.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-1 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-2 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-2; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-7 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-7; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-4 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-3 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-3; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-8.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-8 0; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-1 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-3.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-3 0; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-0 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-0, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-2 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-8 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-4, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-5, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-6, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-7, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-8, decimal) " "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-8 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-8; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-3 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-7.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-7 0; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-6 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-6 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-4, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-5, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-6, decimal) " "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-6 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-6; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-7.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-7 0; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-2.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-2 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-0 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) " "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-4 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-4, decimal) " "; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-6 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-6; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-7 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-7; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-4 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-6 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-6; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-7.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-7 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-2 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) " "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-7 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-7; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-0 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-3 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-3; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-5.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-5 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-8 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-8; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-8 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-6 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-5.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-5 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-4 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-7 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-7; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-4 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-4 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-2 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-6 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-0.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-0 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-2 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-6 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-0 > li:before {
  content: "4." counter(lst-ctn-kix_cr1x8qnw3ql3-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-8 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-0 > li:before {
  content: "7." counter(lst-ctn-kix_xbkh5jbilemk-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-8 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-6 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-8 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-4 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-4, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-8.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-8 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-7 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-7; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-3 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-3; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-0 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-1.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-1 0; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-5.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-5 0; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-3 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-3; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-0 > li:before {
  content: "8." counter(lst-ctn-kix_61jrq37k6olb-0, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-7.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-7 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-2 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-3 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-3; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-7.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-7 0; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-1 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-1; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-0 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-8 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-8; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-7 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-7, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-8 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-1 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-1; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-5 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-1 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-1; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-0.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-0 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-4 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-4; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-4.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-4 0; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-1.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-1 1; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-6 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-6; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-3.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-3 0; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-8.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-8 0; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-7.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-7 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-2 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-2; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-3 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-3.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-3 0; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-7 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-0.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-0 0; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-0 > li:before {
  content: "10." counter(lst-ctn-kix_cr0oxql4jyzv-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-8 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-2 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-2; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-3.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-3 0; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-3.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-3 0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-6.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-6 0; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-4 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-4, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-4.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-4 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-1 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-1; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-1.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-1 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-4.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-4 0; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-1 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-2.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-2 0; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-2 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-0 > li:before {
  content: "2." counter(lst-ctn-kix_5jgjpru4022i-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-6 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-6; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-1 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-4.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-4 0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-5.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-5 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-8.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-8 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-4 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-4, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-4.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-4 0; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-4 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-4; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-4 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-4; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-5 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-5; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-2.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-2 0; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-3.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-3 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-4 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-5 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-5, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-8.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-8 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-1 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-1; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-0 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-0; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-3.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-3 0; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-5 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-8 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-6 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-2.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-2 0; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-1.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-1 0; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-1 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-5 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-4, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-5, decimal) " "; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-2 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-7 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-2.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-2 0; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-8 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-8, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-0.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-0 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-1 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) " "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-4 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-3 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-3, decimal) ". "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-0 > li:before {
  content: "1.3." counter(lst-ctn-kix_upykc4f5hg3k-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-8 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-8; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-4 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-4; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-1 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-5 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-5, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-2.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-2 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-0 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-0; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-4.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-4 0; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-2 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-2; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-5 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-5, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-3.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-3 0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-1.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-1 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-7 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-7; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-6.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-6 0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-7 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-7, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-1 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-3 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-3; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-5 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-5, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-7.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-7 0; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-3.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-3 0; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-2.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-2 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-1.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-1 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-1 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-3 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-4.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-4 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-5 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-4 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-4; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-5.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-5 0; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-0 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-6 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-6; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-4 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-4; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-2.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-2 0; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-3 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-3; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-1.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-1 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-2.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-2 0; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-5.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-5 0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-5 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-5; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-8.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-8 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-4 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-4; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-0.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-0 0; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-1.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-1 0; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-0.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-0 0; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-4 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-4; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-6 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-2.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-2 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-0 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-7 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-4, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-5, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-6, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-7, decimal) " "; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-7 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-6 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-4, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-5, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-6, decimal) " "; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-6.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-6 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-5 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-4, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-5, decimal) " "; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-6.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-6 0; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-4 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-4 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-4; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-1 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-1; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-4 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-4, decimal) " "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-5 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-5; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-0 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-0; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-5.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-5 0; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-1.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-1 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-8 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-4, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-5, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-6, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-7, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-8, decimal) " "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-8.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-8 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-5 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-5; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-5 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-5; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-6.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-6 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-5 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-5; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-0.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-0 0; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-6.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-6 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-1 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) " "; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-8.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-8 0; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-2 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-2; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-3 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-3, decimal) " "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-2 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-2; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-3.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-3 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-2 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-1, decimal) "." counter(lst-ctn-kix_7iltzwgr8ln0-2, decimal) " "; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-4.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-4 0; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-8.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-8 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-0 > li:before {
  content: "" counter(lst-ctn-kix_7iltzwgr8ln0-0, decimal) ". "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-5 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-5; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-7.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-7 0; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-6 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-6; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-2 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-2; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-0.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-0 0; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-5.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-5 0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-4.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-4 0; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-7 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_xbkh5jbilemk-1.start {
  counter-reset: lst-ctn-kix_xbkh5jbilemk-1 0; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-8 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-3 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-3, decimal) ". "; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-5 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-6 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-6; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-2 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-6 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-1 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-1; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-4 > li:before {
  content: "" counter(lst-ctn-kix_vcatdoueysiw-4, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-3.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-3 0; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-6 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-1 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-1; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-5 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-2 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-2; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-0 > li:before {
  content: "1." counter(lst-ctn-kix_vcatdoueysiw-0, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-6.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-6 0; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-7 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-6 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-5 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-5, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-5 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-8 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-8; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-1.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-1 0; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-8.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-8 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-0 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-0, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-2 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-2 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-2; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-2.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-2 0; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-4 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-3 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-0.start {
  counter-reset: lst-ctn-kix_jhvm5kp3mbln-0 3; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-6 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-4 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-4, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-7 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-7; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-1 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-1, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-7.start {
  counter-reset: lst-ctn-kix_mxnsliuumgmy-7 0; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-0 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-8 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-8, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_mxnsliuumgmy-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-0.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-0 0; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-5 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-5; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-2 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-2; }

#PrivacyPolicy ol.lst-kix_7iltzwgr8ln0-7.start {
  counter-reset: lst-ctn-kix_7iltzwgr8ln0-7 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-8 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-8; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-6 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-5 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-5; }

#PrivacyPolicy ol.lst-kix_61jrq37k6olb-8.start {
  counter-reset: lst-ctn-kix_61jrq37k6olb-8 0; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-8 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-8; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-2 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-2; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-1 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-1; }

#PrivacyPolicy ol.lst-kix_vcatdoueysiw-1.start {
  counter-reset: lst-ctn-kix_vcatdoueysiw-1 0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-6 > li {
  counter-increment: lst-ctn-kix_jhvm5kp3mbln-6; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_qxl1aouwtadn-6.start {
  counter-reset: lst-ctn-kix_qxl1aouwtadn-6 0; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-7 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-7; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_f1ff1elfy1yp-8.start {
  counter-reset: lst-ctn-kix_f1ff1elfy1yp-8 0; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-6 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-6; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-2 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-2; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-5 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-7 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-7; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-3 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-1 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-1; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-0 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-4 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-7 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-7; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-8.start {
  counter-reset: lst-ctn-kix_5jgjpru4022i-8 0; }

#PrivacyPolicy ol.lst-kix_jhvm5kp3mbln-8 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-8 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-8; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-5.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-5 0; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-3 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-3; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-3 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-6.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-6 0; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-0 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-6 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-6; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-0.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-0 0; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-7 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-3 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-3; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-5 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-5; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-1 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-2 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-6 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-2 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-1 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-5.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-5 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-8 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-8; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-8 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-8; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-5 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-5; }

#PrivacyPolicy .lst-kix_vcatdoueysiw-3 > li {
  counter-increment: lst-ctn-kix_vcatdoueysiw-3; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-5 > li {
  counter-increment: lst-ctn-kix_upykc4f5hg3k-5; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-7.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-7 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-1 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-1; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-2 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-2; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-5 > li:before {
  content: "" counter(lst-ctn-kix_cr0oxql4jyzv-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-5 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-5; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-8 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-8, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_qxl1aouwtadn-4 > li {
  counter-increment: lst-ctn-kix_qxl1aouwtadn-4; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-8 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-8; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-1 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-1; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-7 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-7; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-4 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-3 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-5.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-5 0; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-4.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-4 0; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-0 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-0, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-3 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-3; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-4 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-4; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-0 > li {
  counter-increment: lst-ctn-kix_mxnsliuumgmy-0; }

#PrivacyPolicy ol.lst-kix_cr1x8qnw3ql3-8.start {
  counter-reset: lst-ctn-kix_cr1x8qnw3ql3-8 0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-3 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-3, decimal) ". "; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-1 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-1; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-2 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-6 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-6, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-0.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-0 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-5 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-5; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-8 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-8; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-6.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-6 0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-7 > li:before {
  content: "" counter(lst-ctn-kix_5jgjpru4022i-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-6.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-6 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-6 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-6; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-7 > li:before {
  content: "" counter(lst-ctn-kix_wnz9dsd7par4-7, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-4 > li {
  counter-increment: lst-ctn-kix_f1ff1elfy1yp-4; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-4.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-4 0; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-5 > li {
  counter-increment: lst-ctn-kix_cr1x8qnw3ql3-5; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-0 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-0, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-4 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-4, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-1 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-7 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-4, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-5, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-6, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-7, decimal) " "; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-1 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-1; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-8 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-7 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-6 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-5 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-4 {
  list-style-type: none; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-3 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-3 > li {
  counter-increment: lst-ctn-kix_alrs1v1z5fle-3; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-2 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-0 > li {
  counter-increment: lst-ctn-kix_yupizdh22lmd-0; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-1 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_jhvm5kp3mbln-3 > li:before {
  content: "" counter(lst-ctn-kix_jhvm5kp3mbln-0, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-1, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-2, decimal) "." counter(lst-ctn-kix_jhvm5kp3mbln-3, decimal) " "; }

#PrivacyPolicy ol.lst-kix_5jgjpru4022i-0 {
  list-style-type: none; }

#PrivacyPolicy .lst-kix_yupizdh22lmd-5 > li:before {
  content: "" counter(lst-ctn-kix_yupizdh22lmd-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-6 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-6, decimal) ". "; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-4 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-4; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-0 > li {
  counter-increment: lst-ctn-kix_61jrq37k6olb-0; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-6.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-6 0; }

#PrivacyPolicy .lst-kix_5jgjpru4022i-6 > li {
  counter-increment: lst-ctn-kix_5jgjpru4022i-6; }

#PrivacyPolicy .lst-kix_cr0oxql4jyzv-3 > li {
  counter-increment: lst-ctn-kix_cr0oxql4jyzv-3; }

#PrivacyPolicy .lst-kix_upykc4f5hg3k-2 > li:before {
  content: "" counter(lst-ctn-kix_upykc4f5hg3k-2, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-3 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-1.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-1 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-7 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-4.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-4 0; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-5.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-5 0; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-3.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-3 0; }

#PrivacyPolicy .lst-kix_f1ff1elfy1yp-3 > li:before {
  content: "" counter(lst-ctn-kix_f1ff1elfy1yp-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-1.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-1 0; }

#PrivacyPolicy .lst-kix_mxnsliuumgmy-7 > li:before {
  content: "" counter(lst-ctn-kix_mxnsliuumgmy-7, lower-latin) ". "; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-2.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-2 0; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-7.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-7 0; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-5 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-5, lower-roman) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-3 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-3, decimal) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-1 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-1; }

#PrivacyPolicy .lst-kix_cr1x8qnw3ql3-1 > li:before {
  content: "" counter(lst-ctn-kix_cr1x8qnw3ql3-1, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-7 > li:before {
  content: "" counter(lst-ctn-kix_xbkh5jbilemk-7, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-7 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-7, lower-latin) ". "; }

#PrivacyPolicy .lst-kix_61jrq37k6olb-3 > li:before {
  content: "" counter(lst-ctn-kix_61jrq37k6olb-3, decimal) ". "; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-4.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-4 0; }

#PrivacyPolicy .lst-kix_xbkh5jbilemk-8 > li {
  counter-increment: lst-ctn-kix_xbkh5jbilemk-8; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-2.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-2 0; }

#PrivacyPolicy .lst-kix_7iltzwgr8ln0-3 > li {
  counter-increment: lst-ctn-kix_7iltzwgr8ln0-3; }

#PrivacyPolicy .lst-kix_wnz9dsd7par4-6 > li {
  counter-increment: lst-ctn-kix_wnz9dsd7par4-6; }

#PrivacyPolicy ol.lst-kix_yupizdh22lmd-6.start {
  counter-reset: lst-ctn-kix_yupizdh22lmd-6 0; }

#PrivacyPolicy li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt; }

#PrivacyPolicy ol.lst-kix_wnz9dsd7par4-7.start {
  counter-reset: lst-ctn-kix_wnz9dsd7par4-7 0; }

#PrivacyPolicy ol.lst-kix_upykc4f5hg3k-3.start {
  counter-reset: lst-ctn-kix_upykc4f5hg3k-3 0; }

#PrivacyPolicy ol.lst-kix_cr0oxql4jyzv-5.start {
  counter-reset: lst-ctn-kix_cr0oxql4jyzv-5 0; }

#PrivacyPolicy .lst-kix_alrs1v1z5fle-8 > li:before {
  content: "" counter(lst-ctn-kix_alrs1v1z5fle-8, lower-roman) ". "; }

#PrivacyPolicy ol.lst-kix_alrs1v1z5fle-3.start {
  counter-reset: lst-ctn-kix_alrs1v1z5fle-3 0; }

#PrivacyPolicy ol {
  margin: 0;
  padding: 0; }

#PrivacyPolicy table td,
#PrivacyPolicy table th {
  padding: 0; }

#PrivacyPolicy .c0 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 85.5pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c1 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 18pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c11 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 36pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c9 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 166.5pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c19 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 67.5pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c6 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 49.5pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c13 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 54pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c20 {
  border-right-style: solid;
  padding-top: 0pt;
  border-top-width: 0pt;
  border-bottom-color: #000000;
  border-right-width: 0pt;
  padding-left: 0pt;
  border-left-color: #000000;
  padding-bottom: 0pt;
  line-height: 1.5;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  margin-left: 72pt;
  border-left-style: solid;
  border-bottom-width: 0pt;
  border-top-color: #000000;
  border-bottom-style: solid;
  text-align: justify;
  padding-right: 0pt; }

#PrivacyPolicy .c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#PrivacyPolicy .c5 {
  margin-left: 85.5pt;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify; }

#PrivacyPolicy .c14 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify; }

#PrivacyPolicy .c10 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify;
  height: 11pt; }

#PrivacyPolicy .c4 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal; }

#PrivacyPolicy .c12 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

#PrivacyPolicy .c18 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  text-align: justify; }

#PrivacyPolicy.mainContent {
  background-color: #ffffff;
  padding: 0 3rem; }

#PrivacyPolicy .c8 {
  font-weight: 400; }

#PrivacyPolicy .c3 {
  font-weight: 700; }

#PrivacyPolicy .c17 {
  font-weight: 400; }

#PrivacyPolicy .c7 {
  padding: 0;
  margin: 0; }

#PrivacyPolicy .c21 {
  color: inherit;
  text-decoration: inherit; }

#PrivacyPolicy .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy li {
  color: #000000; }

#PrivacyPolicy p {
  margin: 0;
  color: #000000; }

#PrivacyPolicy h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left; }

#PrivacyPolicy h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left; }

