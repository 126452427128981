.map-image{
    width: 600px;
    height: 600px;
    object-fit: cover !important;
    object-position: 100% 20%;      
    border-radius: 100%;  
}

.marker{
    color: #f2555c !important;
    font-size: 70px;    
    position: absolute;
    left: 52%;
    top: 30%;
}

.map-container{
    padding-left: 25%;
    padding-top: 5%;
    padding-bottom: 5%;
}
