#Footer {
    *:focus {outline:0 !important;}
    
    .link-button {
        background-color: white !important;
        border-color: white !important;
        color: #f2555c !important;
        font-weight: bold !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        border-radius: 0 !important;
        &:focus {outline:0 !important;}
    }

}
