$blue-color: #39517a;
$red-color: #f2555c;
#BatchProccesorContainer {
    padding-top: 5%;
    padding-bottom: 20px;

    h1 {
        color: $blue-color;
        font-weight: bold;
        text-align: left;
        font-size: 25pt;
    }

    h2 {
        color: $blue-color;
        font-weight: bold;
        text-align: left;
    }

    .appends-title {
        padding-top: 50px;
    }

    .file-loader {
        text-align: center;
        padding-top: 20px;
        padding-right: 20px;
    }

    .check-container {
        color: $blue-color;
        font-weight: bold;
        padding: 0px;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        margin: 20px;
        margin-left: 1;
        background-color: white;
        border-radius: 20px;
        height: 45px;
        border: 1px solid rgba(57,81,122,0.3);
    }

    .btn, button {
        color: white !important;
        font-size: 14pt;
        font-weight: bold;
        background-color: $blue-color !important;
        border-color: $blue-color !important;
        border-radius: 0 !important;
        padding-left: 40px !important;
        padding-right: 40px !important;

        &:hover {
            background-color: #557ab9;
        }

        border-width: 0;
        box-shadow: none;
        margin-right: 20px;
        margin-left: 20px;
    }

    .btn {
        margin-top: -8px;
    }

    span {
        button {
            padding: 5px;
        }
    }

    .metadataBox {
        padding: 20px;
        border-radius: 40px;
        background-color: #e1e6ec;
        text-align: center;
        border: 20px solid #fff;

        &:hover {
            background-color: #d8e2f0;
        }

        h1 {
            text-align: center;
            font-size: 30pt;
        }

        span {
            font-size: 15pt;
        }
    }
}
