$blue-color: #39517a;
#Pricing {
    background-color: #f5f9ff;
    h1 {
        color: $blue-color;
        font-weight: bold;
        text-align: center;
        font-size: 25pt;
    }
    h2 {
        color: $blue-color !important;
        font-style: italic;
        font-weight: bold;
        font-size: 12pt;
    }
    p{
        color: $blue-color;
    }
    .pricing-text {
        padding: 100px;
        color: $blue-color;
        font-size: 15pt;
    }
    .center-text {
        padding-top: 130px !important;
    }
    .title {
        padding-top: 30px;
    }
    .card-icon {
        font-size: 35pt;
        color: #f2555c;
    }
    .sign-up-btn {
        background-color: #f2555c;
        color: white;
        border-color: #f2555c;
        font-size: 15pt;
        font-weight: bold;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 0% !important;
        &:hover {
        background-color: #ff7d83;
        }
    }
    .btn-container{
        padding-top: 80px;
        padding-bottom: 100px;
    }
}
